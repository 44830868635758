<template>
  <aside class="sidebar">
    <div class="sidebar-header">
      <h3>Painel de Controle</h3>
    </div>
    <nav class="sidebar-nav">
      <ul>
        <li v-for="item in menuItems" :key="item.path">
          <CanAccess :action="item.action" :subject="item.subject">
            <NuxtLink :to="item.path" class="sidebar-link" active-class="active">
              <i :class="item.icon"></i>
              {{ item.label }}
            </NuxtLink>
          </CanAccess>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script setup>
import CanAccess from '@/components/CanAccess.vue';
import { useAbility } from '~/composables/useAbility'
import { useAuthStore } from '@/stores/auth'
import { ref, onMounted } from 'vue'


const authStore = useAuthStore()
const { updateAbility } = useAbility()

const menuItems = [
  { label: 'Dashboard', path: '/dashboard', icon: 'pi pi-home', action: '', subject: '' },
  { label: 'Lojas', path: '/stores', icon: 'pi pi-shopping-cart', action: ['manage','see','edit'], subject: 'stores'},
  { label: 'Clientes', path: '/customers', icon: 'pi pi-users', action: ['manage','see','edit'], subject: 'customers' },
  { label: 'Relatórios', path: '/reportgenerator', icon: 'pi pi-file-o', action: ['manage','see','edit'], subject: 'stores' },
  { label: 'Usuários', path: '/users', icon: 'pi pi-users', action: ['manage','see','edit'], subject: 'users' },
  { label: 'Permissões', path: '/permissions', icon: 'pi pi-cog', action: ['manage','see','edit'], subject: 'permissions' },
  { label: 'Perfis', path: '/roles', icon: 'pi pi-cog', action: ['manage','see','edit'], subject: 'roles' }
]
onMounted(() => {
  console.log('Permissões do usuário:', authStore.user?.all_permissions)
  console.log('Estado de autenticação:', authStore.isAuthenticated);
  console.log('Usuário:', authStore.user);
  console.log('Permissões do usuário:', authStore.user?.all_permissions);
  updateAbility();
})
</script>

<style scoped>
.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #2e225e;
  border-right: 1px solid #dee2e6;
  padding: 1rem;
  margin:0;
}

.sidebar-header {
  padding-bottom: 1rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 1rem;
}

.sidebar-nav ul {
  list-style-type: none;
  padding: 0;
}

.sidebar-link {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  color: #8c8c8c;
  text-decoration: none;
  transition: background-color 0.3s;
}

.sidebar-link:hover,
.sidebar-link.active {
  background-color: #e9ecef;
}

.sidebar-link i {
  margin-right: 0.5rem;
}

h3 {
  color:#8c8c8c;
}
</style>
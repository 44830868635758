<template>
  <slot v-if="canAccess"></slot>
</template>

<script setup>
import { computed} from 'vue'
import { useAbility } from '~/composables/useAbility'

const props = defineProps({
  action: {
    type: String,
    required: true,
  },
  subject: {
    type: String,
    default: 'all',
  },
})

const { can } = useAbility()


const canAccess = computed(() => {
  console.log(`Verificando permissão: ${props.action} ${props.subject}`);
  const result = can(props.action, props.subject);
  console.log(`Resultado: ${result}`);
  return result;
});
</script>
<!-- layouts/dashboard.vue -->
<template>
  <div class="dashboard-layout">
    <SidebarMenu />
    <main class="dashboard-content">
      <header class="dashboard-header">
        <h1>{{ title }}</h1>
        <div class="user-menu">
          <Button icon="pi pi-user" label="Usuário" class="p-button-text" @click="toggleUserMenu" />
          <Menu ref="userMenu" :model="userMenuItems" :popup="true" />
        </div>
      </header>
      <div class="dashboard-main">
        <slot />
      </div>
    </main>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import SidebarMenu from '~/components/Sidebar/SidebarMenu.vue'

const route = useRoute()
const userMenu = ref()

const title = computed(() => {
  return route.meta.title || 'Dashboard'
})

const userMenuItems = [
  {
    label: 'Perfil',
    icon: 'pi pi-user-edit',
    command: () => {
      // Navegar para a página de perfil
    }
  },
  {
    label: 'Sair',
    icon: 'pi pi-power-off',
    command: () => {
      // Lógica de logout
    }
  }
]

const toggleUserMenu = (event) => {
  userMenu.value.toggle(event)
}
</script>

<style>
.dashboard-layout {
  display: flex;
  min-height: 100vh;
}

.dashboard-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #2e225e;
  border-bottom: 1px solid #dee2e6;
}

.dashboard-main {
  flex: 1;
  padding: 2rem;
  background-color: #f8f9fa;
}

h1 {
  color:#c0c0c0
}
</style>